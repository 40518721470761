import { type ComponentProps, useId } from "react";

export default function InvoiceIcon(svgProps: ComponentProps<"svg">) {
  const pathId = useId();
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      aria-label="Invoice"
      {...svgProps}
    >
      <g clipPath={`url(#${pathId}])`}>
        <path
          d="M9 0C4.02903 0 0 4.02942 0 9C0 13.9706 4.02903 18 9 18C13.971 18 18 13.9702 18 9C18 4.02981 13.9706 0 9 0ZM9.5448 13.0091V14.0509C9.5448 14.2134 9.41829 14.3317 9.25536 14.3317H8.56095C8.39802 14.3317 8.26681 14.2134 8.26681 14.0509V13.1007C7.62762 13.0717 6.98294 12.9354 6.49493 12.7498C6.23056 12.6495 6.08329 12.3663 6.1534 12.0922L6.26659 11.6512C6.30615 11.4965 6.40955 11.3672 6.55094 11.2947C6.69233 11.2211 6.85839 11.2137 7.00683 11.2705C7.47173 11.4502 8.02084 11.5811 8.60716 11.5811C9.35367 11.5811 9.86479 11.2928 9.86479 10.7687C9.86479 10.2709 9.44571 9.95644 8.47596 9.62862C7.0742 9.15706 6.11423 8.5022 6.11423 7.23125C6.11423 6.07859 6.93007 5.17464 8.32477 4.8993V3.94913C8.32477 3.78659 8.46381 3.64089 8.62675 3.64089H9.32116C9.48409 3.64089 9.60355 3.78659 9.60355 3.94913V4.80726C10.2134 4.8335 10.6536 4.92397 11.0218 5.04382C11.3002 5.13469 11.4647 5.42648 11.3915 5.71082L11.292 6.10562C11.2532 6.25602 11.1537 6.3837 11.0175 6.45733C10.8812 6.53096 10.7202 6.54428 10.5729 6.49375C10.2384 6.379 9.80212 6.27482 9.2491 6.27482C8.39762 6.27482 8.12229 6.6418 8.12229 7.00879C8.12229 7.44119 8.58092 7.71613 9.69442 8.1356C11.2532 8.68589 11.8795 9.40654 11.8795 10.5851C11.8791 11.751 11.0554 12.7474 9.5448 13.0091Z"
          fill="#B8B8B8"
        />
      </g>
      <defs>
        <clipPath id={pathId}>
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
