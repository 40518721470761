import { restApiClient } from "$/lib/utils/clients/restApi.client";
import type { DatePickerRange } from "$/types/misc.types";
import type { SortOrder } from "$/types/util.types";

import type {
  CreateDeliveryRequestPayloadType,
  GetDeliveryRequestResponse,
  GetDeliveryRequestsListEntity,
} from "../types/delivery-request-apis.types";
import type { DeliveryRequestStatusEnum } from "../types/delivery-request.types";

export async function createDeliveryRequest(
  payload: CreateDeliveryRequestPayloadType,
) {
  return await restApiClient.url("/delivery-requests").post<never>(payload);
}

export async function deleteDeliveryRequest(requestId: number) {
  return restApiClient.url(`/delivery-requests/${requestId}`).delete();
}

export async function editDeliveryRequest(
  requestId: number,
  payload: CreateDeliveryRequestPayloadType,
) {
  return restApiClient
    .url(`/delivery-requests/${requestId}`)
    .patch<never>(payload);
}

export async function getDeliveryRequests(
  sorting?: { key: keyof GetDeliveryRequestResponse; order: SortOrder },
  pagination?: { offset: number; limit: number },
  filters?: {
    search?: string;
    dateRange?: DatePickerRange;
    statuses?: DeliveryRequestStatusEnum[];
  },
): Promise<GetDeliveryRequestResponse> {
  return restApiClient
    .url("/delivery-requests/all")
    .post<GetDeliveryRequestResponse>({
      filters,
      pagination,
      sorting,
    });
}

export async function publishDeliveryRequest(id: number) {
  return restApiClient.url(`/delivery-requests/${id}/publish`).post<never>();
}

export async function getDeliveryRequestsList(
  pagination?: { offset: number; limit: number },
  filters?: {
    search?: string;
  },
) {
  return restApiClient
    .url(`/delivery-requests/list`)
    .post<GetDeliveryRequestsListEntity[]>({ ...pagination, ...filters });
}
