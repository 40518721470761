import type { FieldValues } from "react-hook-form";
import { useFormContext } from "react-hook-form";

import { getDeepFormError } from "$/lib/utils/functions/misc.functions";
import type { FormInput } from "$/types/misc.types";

import TextInput from "../../ui/inputs/TextInput";

type Props<T extends FieldValues> = FormInput<T> & {
  placeholder?: string;
  autoComplete?: boolean;
  className?: string;
  hideLabel?: boolean;
  readonly?: boolean;
  suggestions?: string[];
};

const FormTextInput = <T extends FieldValues>({
  name,
  label,
  hideLabel,
  placeholder,
  autoComplete,
  className,
  readonly,
  suggestions,
  disabled,
  ...registerOptions
}: Props<T>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<T>();

  const error = getDeepFormError(errors, name.split("."));

  const registerProps = register(name, {
    ...registerOptions,
    setValueAs: (value) => value || undefined,
  });

  return (
    <TextInput
      {...registerProps}
      disabled={disabled}
      label={label}
      hideLabel={hideLabel}
      autoComplete={autoComplete}
      placeholder={placeholder}
      readOnly={readonly}
      suggestions={suggestions}
      className={className}
      error={error?.message?.toString()}
    />
  );
};

export default FormTextInput;
