import type { ComponentProps } from "react";

export default function FlagIcon(svgProps: ComponentProps<"svg">) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      aria-label="Flag"
      {...svgProps}
    >
      <path d="M9.2616 1.12423C6.93703 -0.204047 4.49395 -0.328289 2.14453 0.732164V0H1.08984V18H2.14453V11.4352C4.30907 10.2998 6.58216 10.3352 8.7384 11.5672C10.0315 12.3062 11.3612 12.6754 12.6914 12.6754C14.0211 12.6754 15.3513 12.3062 16.6444 11.5672L16.9102 11.4153V0.673102L16.1212 1.12423C13.8779 2.40652 11.5059 2.40652 9.2616 1.12423Z" />
    </svg>
  );
}
